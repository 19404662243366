<template>
  <div class="main-container">
    <router-view></router-view>
    <!-- Navbar section -->
    <HeaderSoftware />

    <!-- hero section -->
    <HeroSoftware />

    <!-- brand logo carousel -->
    <BrandLogoCarousel />

    <!-- business analytics start -->
    <div
      class="feature-icon-list-area section-space--pb_120 section-space--mt_40"
      id="about"
    >
      <div class="container">
        <div class="section-title-wrapper text-center section-space--mb_100">
          <h2 class="section-title mb-30 wow move-up">¿Quíenes somos?</h2>
          <div class="text">
            Somos una empresa <b>Innovadora</b> que te ofrece soluciones de
            <b>Tecnología, Diseño y experiencia,</b> que te ayuden a
            <b>posicionar tu marca</b> y ofrecer una mejor
            <b>experiencia a tus clientes.</b>
          </div>
          <!-- <div class="video-button-container video-popup">
            <div class="video-play" @click="misionVisionModal = true">
              <a class="btn btn--accent-300">
                Conoce más
              </a>
            </div>
          </div> -->
        </div>
      </div>
      <!-- feature list component -->
      <FeatureList />
    </div>

    <!-- tab two components -->
    <TabTwo BgGradient="bg-gradient" id="service" />

    <!-- accordion section -->
    <div
      class="software-accordion-wrapper section-space--pt_120 section-space--pb_60 bg-neutral-100"
      id="faq"
    >
      <Portafolio />
    </div>

    <CurveShape class="curve-bottom">
      <template v-slot:bgcolor>
        <path
          style="fill: #f6f5f9; stroke-miterlimit: 10"
          d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"
        />
      </template>
    </CurveShape>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <CasosExito />

    <!-- pricing section -->
    <CurveShape>
      <template v-slot:bgcolor>
        <path
          style="fill: #2d2d2f; stroke-miterlimit: 10"
          d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"
        />
      </template>
    </CurveShape>
    <div
      class="pricing-table-area section-space--pb_90 bg-primary-400"
      id="pricing"
    >
      <div class="pricing-table-title-area position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 wow move-up">
              <div
                class="section-title-wrapper text-center section-space--mb_60 mt-50"
              >
                <h2
                  class="section-title mb-15 wow move-up text-color-neutral-100 underline"
                >
                  PAQUETES DE INICIO
                </h2>
                <span
                  class="section-text wow move-up text-color-neutral-300 font-weight-600"
                  >Alguno de estos paquetes te pueden ayudar a iniciar tu
                  proyecto.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricing-table-content-area">
        <div class="container">
          <!-- pricing component -->
          <pricingTwo />
        </div>
      </div>
    </div>

    <!-- contact form section -->
    <ContactForm />

    <ModalAP
      v-if="avisoPrivacidadModal"
      @close="avisoPrivacidadModal = false"
    />

    <ModalMV v-if="misionVisionModal" @close="misionVisionModal = false" />

    <!-- footer section -->
    <FooterMain @openModal="avisoPrivacidadModal = true" />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
    <div style="position: fixed; bottom: 10px; right: -50px; z-index: 99999">
      <div class="float-button-wrapper rounded-circle pulse">
        <div class="box-shadow rounded-circle">
          <a
            href="https://api.whatsapp.com/send?phone=4431371292&amp;text=Olá!%20Quero%20comprar%20o%20produto."
            target="_blank"
            aria-label="whatsapp"
            rel="noreferrer noopener"
          >
            <img
              class="float-button"
              src="https://image.ibb.co/jxU6f9/whatsapp.png"
              alt="whatsapp"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSoftware from '@/components/HeaderSoftware.vue'
import HeroSoftware from '@/components/HeroSoftware.vue'
import BrandLogoCarousel from '@/components/BrandLogoCarousel.vue'
import FeatureList from '@/components/FeatureList.vue'
import TabTwo from '@/components/TabTwo.vue'
import pricingTwo from '@/components/pricingTwo.vue'
import CurveShape from '@/components/CurveShape.vue'
import FooterMain from '@/components/FooterMain.vue'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox.vue'
import ContactForm from '@/components/ContactForm.vue'
import Portafolio from '@/components/Portafolio.vue'
import CasosExito from '@/components/CasosExito.vue'
import ModalAP from '@/components/modals/AvisoPrivacidad.vue'
import ModalMV from '@/components/modals/MisionVision.vue'

export default {
  name: 'app',
  components: {
    HeaderSoftware,
    HeroSoftware,
    BrandLogoCarousel,
    FeatureList,
    TabTwo,
    pricingTwo,
    CurveShape,
    FooterMain,
    OffcanvasSearchBox,
    ContactForm,
    Portafolio,
    CasosExito,
    ModalAP,
    ModalMV,
  },

  data: () => ({
    avisoPrivacidadModal: false,
    misionVisionModal: false,
  }),

  metaInfo: {
    title: 'Leonix',
    htmlAttrs: {
      lang: 'en',
      amp: true,
    },
  },
}
</script>

<style scoped>
.float-button-wrapper {
  position: fixed;
  z-index: 20;
  right: 30px;
  bottom: 30px;
}

.pulse {
  box-shadow: 0 0 0 rgba(92, 168, 44, 0.4);
  animation: pulse 2s infinite;
}

img.float-button {
  width: 60px;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(92, 168, 44, 0.5);
    box-shadow: 0 0 0 0 rgba(92, 168, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(92, 168, 44, 0);
    box-shadow: 0 0 0 30px rgba(92, 168, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(92, 168, 44, 0);
    box-shadow: 0 0 0 0 rgba(92, 168, 44, 0);
  }
}

.rounded-circle {
  border-radius: 50% !important;
}
</style>