<template>
  <div class="contact-form-section section-space--ptb_120" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 order-2 order-lg-1">
          <div class="section-title text-left section-space--mb_60">
            <h2 class="mb-15 wow move-up font-weight-800 underline">
              Contacto
            </h2>
            <br />
            <span class="section-text_left wow move-up mt-20"
              >Escríbenos y cuéntanos tu proyecto, en breve te contactaremos
              sin ningún compromiso.</span
            >
            <h6 class="mt-20 font-weight-700">¡Nosotros te podemos ayudar!</h6>
          </div>
          <div class="contact-from-wrapper wow move-up">
            <form id="contact-form" @submit.prevent="validate">
              <div class="contact-page-form">
                <div class="contact-input">
                  <div class="contact-inner">
                    <input
                      name="con_name"
                      type="text"
                      placeholder="Tu nombre *"
                      v-model.trim="$v.form.name.$model"
                    />
                    <span
                      class="error-message"
                      v-if="!$v.form.name.required && $v.form.name.$dirty"
                      >Campo Requerido</span
                    >
                    <span v-else style="color: white;">.</span>
                  </div>
                  <div class="contact-inner">
                    <input
                      name="con_email"
                      type="email"
                      placeholder="Tu correo *"
                      v-model.trim="$v.form.email.$model"
                    />
                    <span
                      class="error-message"
                      v-if="!$v.form.email.required && $v.form.email.$dirty"
                      >Campo Requerido</span
                    >
                    <span v-else style="color: white;">.</span>
                    <span
                      class="error-message"
                      v-if="!$v.form.email.email && $v.form.email.$dirty"
                      >Correo invalido</span
                    >
                  </div>
                </div>
                <div class="contact-input">
                  <div class="contact-inner">
                    <input
                      name="con_phone"
                      type="text"
                      placeholder="Tu teléfono *"
                      v-model.trim="$v.form.phone.$model"
                      v-mask="'(###)-###-####'"
                    />
                    <span
                      class="error-message"
                      v-if="!$v.form.phone.required && $v.form.phone.$dirty"
                      >Campo Requerido</span
                    >
                    <span v-else style="color: white;">.</span>
                  </div>
                  <div class="contact-inner">
                    <select
                      name="con_services"
                      placeholder="Selecciona el servicio de tu interes"
                      v-model.trim="$v.form.service.$model"
                    >
                      <option disabled selected value=""
                        >Selecciona el servicio de tu interes</option
                      >
                      <option
                        v-for="(service, index) in services"
                        :key="index"
                        :value="service"
                        >{{ service }}
                      </option>
                    </select>
                    <span
                      class="error-message"
                      v-if="!$v.form.service.required && $v.form.service.$dirty"
                      >Campo Requerido</span
                    >
                    <span v-else style="color: white;">.</span>
                  </div>
                </div>
                <div class="contact-inner contact-message">
                  <textarea
                    name="con_message"
                    placeholder="Describe tu necesidad *"
                    v-model.trim="$v.form.need.$model"
                  ></textarea>
                  <span
                    class="error-message"
                    v-if="!$v.form.need.required && $v.form.need.$dirty"
                    >Campo Requerido</span
                  >
                </div>
                <div class="contact-submit-btn">
                  <button
                    class="btn btn--accent-500"
                    type="submit"
                    :disabled="sending"
                  >
                    {{ buttonMessage }}
                  </button>
                  <p class="form-messege"></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 order-1 order-lg-2">
          <div class="peatures_image-wrap section-space--mb_40">
            <div class="image text-center wow move-up">
              <img
                class="img-fluid"
                src="../assets/img/features/img-contacto.png"
                alt="contact thumb"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'
import { required, email } from 'vuelidate/lib/validators'
export default {
  name: 'ContactForm',
  validations: {
    form: {
      name: { required },
      email: { required, email },
      phone: { required },
      service: { required },
      need: { required },
    },
  },
  data: function() {
    return {
      sending: false,
      buttonMessage: 'Enviar',
      form: {
        name: '',
        email: '',
        phone: '',
        service: '',
        need: '',
      },
      services: [
        'Necesito una aplicación móvil',
        'Necesito una página web',
        'Ocupo un sistema para mi negocio o empresa',
        'Necesito un logo',
        'Ocupo quien me ayude a administrar mi página',
        'No se por donde empezar, necesito una asesoría',
      ],
    }
  },
  methods: {
    validate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) this.sendEmail()
    },
    sendEmail: function() {
      this.buttonMessage = 'Enviando...'
      this.sending = true
      emailjs
        .send(
          'service_j48wtf5',
          'template_5adq324',
          {
            from_name: this.form.name,
            to_name: 'Leonix',
            mail: this.form.email,
            phone: this.form.phone,
            service: this.form.service,
            message: this.form.need,
          },
          'gWY8hLA_YWOFadA4A'
        )
        .then(
          () => {
            this.$swal(
              'Mensaje enviado!!',
              '¡Gracias por contactarnos! \n En breve nos pondremos en contacto contigo.',
              'Aceptar'
            )
            this.buttonMessage = 'Enviar'
            this.sending = false
          },
          () => {
            this.buttonMessage = 'Enviar'
            this.sending = false
          }
        )
    },
  },
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
