<template>
  <div class="cta-image-area bg-primary-400">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="cta-image">
            <div class="row align-items-center">
              <div class="col-lg-6 order-2 order-lg-1">
                <div class="cta-image__content">
                  <h4 class="cta-image__title wow move-up">
                    Inicia tu proceso de digitalización con Nosotros
                  </h4>
                  <div class="cta-image__text mt-20 wow move-up">
                    Cuéntanos tu idea y juntos logremos materializarla en una
                    aplicación.
                    <br />
                    Nota: Sección adicional se cotiza aparte
                  </div>
                  <!-- <a href="#contact" class="btn btn--green wow move-up">
                    <span class="btn-icon  ml-0 mr-2"
                      ><i class="fa fa-arrow-alt-to-bottom"></i
                    ></span>
                    Contactar
                  </a> -->
                </div>
              </div>
              <div class="col-lg-6 order-1 order-lg-2">
                <div class="cta-image_two section-space--mt_60 wow move-up">
                  <img
                    src="../assets/img/banners/aeroland-macbook-01.png"
                    class="img-fluid"
                    alt="aeroland-macbook-01"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CtaThree',
}
</script>
