<template>
	<div class="main-container">
		<!-- Navbar section -->
		<HeaderDigitalStore />

		<!-- hero section -->
		<HeroDigitalStore />

		<CurveShape class="curve-bottom">
            <template v-slot:bgcolor>
                <path style="fill: #f6f5f9;
                    stroke-miterlimit:10;" 
                    d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
            </template>
        </CurveShape>

		<!--========== digital-store-main-content Start ============-->
        <div class="digital-store-main-content">
            <!--======== digital Store Product Wrapper Start ================-->
            <div class="digital-store-product-wrapper section-space--pb_120" id="products-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="section-title-wrapper section-space--pb_90">
                                <h2 class="section-title">Present <mark> an oustanding mockup collection </mark> developed for higher product presentation. </h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-01.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">Iphone XS Mockup</h5>
                                    <div class="product-category">For Sketch, Photoshop</div>
                                    <div class="product-desc mt-10">The web is teeming with highly realistic iPhone X, XS, XR mockups with outstanding quality. </div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="price"><span class="currency">$</span>29</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="s-product-badge">
                                    <span>Sale</span>
                                </div>
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-02.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">Ipad Pro Mockup</h5>
                                    <div class="product-category">For After effects</div>
                                    <div class="product-desc mt-10">To showcase a website, an app, or any kind of design in action, free iPad PSD mockup templates. </div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="regular-price"><span class="currency">$</span>39</div>
                                            <div class="price"><span class="currency">$</span>19</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-03.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">Notebook Mockup</h5>
                                    <div class="product-category">For Sketch, Photoshop</div>
                                    <div class="product-desc mt-10">Are you looking for notebook mockup vectors or photos? We have 5307 free resources for you. </div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="price"><span class="currency">$</span>29</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-04.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">Ipad Mockup Pack</h5>
                                    <div class="product-category">For Sketch, Photoshop</div>
                                    <div class="product-desc mt-10">Save time with this exhaustive list of more than 150 free PSD logo and book mockups for your next graphic design project.</div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="price"><span class="currency">$</span>20</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-05-570x470.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">Iphone C Mockup</h5>
                                    <div class="product-category">For Sketch, Photoshop</div>
                                    <div class="product-desc mt-10">Fully-scalable vector shape iPhone 5C PSD mockup template in colorful green, blue, yellow, pink, and white.</div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="price"><span class="currency">$</span>20</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-06-570x470.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">United Pixel Mockup</h5>
                                    <div class="product-category">For Sketch, Photoshop</div>
                                    <div class="product-desc mt-10">Born out of some big ideas, AeroLand has always understood people's pride not only in their work but also the. </div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="price"><span class="currency">$</span>19</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <!-- Single Digital Product Wrap Start -->
                            <div class="single-digital-product__wrap mb-30">
                                <div class="single-digital-product__image">
                                    <img class="img-fluid" src="../assets/img/product/aeroland-digital-store-product-07-570x470.jpg" alt="">
                                </div>
                                <div class="single-digital-product__content">
                                    <h5 class="product-name">Iphone X mockup</h5>
                                    <div class="product-category">For Sketch, Photoshop</div>
                                    <div class="product-desc mt-10">The web is teeming with highly realistic iPhone X, XS, XR mockups with outstanding quality. </div>
                                    <div class="product-footer">
                                        <div class="ht-s-product-button">
                                            <a href="#" class="ht-btn ht-btn-md">View product</a>
                                        </div>
                                        <div class="s-product-price">
                                            <div class="price"><span class="currency">$</span>0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Digital Product Wrap End -->
                        </div>

                        <div class="col-lg-12">
                            <div class="view-more-button text-center mt-30">
                                <a href="#" class="ht-btn ht-btn--outline ht-btn-default">View more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--======== digital Store Product Wrapper End ================-->

            <!--======== digital Store countdown Wrapper Start ================-->
            <div class="digital-store-countdown-wrapper section-space--pt_120" id="discount-section">
                <div class="container">
                    <div class="row section-space--pb_90  wow move-up">
                        <div class="col-lg-9 ">
                            <div class="section-title-wrapper">
                                <h2 class="section-title">One year access to all devices, plus updates. <mark>80% discount.</mark></h2>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="view-more-button text-lg-right mt-30">
                                <a href="#" class="ht-btn ht-btn-md">$29 Buy now</a>
                            </div>
                        </div>
                    </div>

                    <div class="row section-space--pb_90  wow move-up">

                        <div class="col-lg-7 mr-auto ml-auto">
                            <div class="timer text-center">
                                <!-- countdown start -->
                                <flip-countdown deadline="2021-12-25" class="countdown-deals counter-style--one"></flip-countdown>
                                <!-- countdown end -->
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-12  wow move-up">
                            <div class="digital-store-banner-image text-center">
                                <img class="img-fluid" src="../assets/img/banners/aeroland-digital-store-image-02.png" alt="">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!--======== digital Store countdown Wrapper End ================-->
        </div>
        <!--========== digital-store-main-content Start ============-->

        <PaymentNewsletter class="section-space--pt_60" id="sign-up-section" />

		<!-- footer section -->
        <FooterMain class="body-gradient" />

	</div>
</template>

<script>
	import HeaderDigitalStore from "../components/HeaderDigitalStore";
	import HeroDigitalStore from "../components/HeroDigitalStore";
	import CurveShape from "../components/CurveShape";
    import PaymentNewsletter from '@/components/sections/PaymentNewsletter';
	import FooterMain from "../components/FooterMain";

    import FlipCountdown from 'vue2-flip-countdown'

	export default {
		name: "app",
		components: {
			HeaderDigitalStore,
			HeroDigitalStore,
			CurveShape,
            PaymentNewsletter,
			FooterMain,
            FlipCountdown 
		},

        metaInfo: {
            title: 'Aeroland - Home Digital Store',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
	};
</script>
