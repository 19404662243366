<template>
    <div class="agency-projects-gradient" id="project">
        <div class="degital-agency-projects-area section-space--ptb_120  degital-project-text-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="digital-agency-section-title text-center section-space--pb_120">
                            <h6 class="section-sub-title mb-20 font-weight--bold wow move-up">Featured Projects</h6>
                            <h3 class="section-title font-weight--light mb-15 wow move-up">The efficiency we focus closely on </h3>
                        </div>
                    </div>
                </div>
                <div class="degital-agency-projects-wrap">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 wow move-up">
                            <div class="agency-projects--wrap mb-30">
                                <a href="#">
                                    <div class="agency-projects__images">
                                        <img src="../../assets/img/portfolio/portfolio--agency-01.jpg" class="img-fluid" alt="portfolio thumb">
                                    </div>
                                    <div class="agency-projects__content">
                                        <div class="post-overlay-content-inner">
                                            <div class="post-overlay-info">
                                                <h6 class="post-overlay-title">Aeroland app</h6>
                                                <h6 class="post-overlay-read-more">Read project <span class="far fa-long-arrow-right"></span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow move-up">
                            <div class="agency-projects--wrap  mb-30">
                                <a href="#">
                                    <div class="agency-projects__images">
                                        <img src="../../assets/img/portfolio/portfolio--agency-02.jpg" class="img-fluid" alt="portfolio thumb">
                                    </div>
                                    <div class="agency-projects__content">
                                        <div class="post-overlay-content-inner">
                                            <div class="post-overlay-info">
                                                <h6 class="post-overlay-title">Aeroland app</h6>
                                                <h6 class="post-overlay-read-more">Read project <span class="far fa-long-arrow-right"></span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow move-up">
                            <div class="agency-projects--wrap  mb-30">
                                <a href="#">
                                    <div class="agency-projects__images">
                                        <img src="../../assets/img/portfolio/portfolio--agency-03.jpg" class="img-fluid" alt="portfolio thumb">
                                    </div>
                                    <div class="agency-projects__content">
                                        <div class="post-overlay-content-inner">
                                            <div class="post-overlay-info">
                                                <h6 class="post-overlay-title">Aeroland app</h6>
                                                <h6 class="post-overlay-read-more">Read project <span class="far fa-long-arrow-right"></span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12 wow move-up">
                            <div class="agency-projects--wrap mb-30">
                                <a href="#">
                                    <div class="agency-projects__images">
                                        <img src="../../assets/img/portfolio/portfolio--agency-04.jpg" class="img-fluid" alt="portfolio thumb">
                                    </div>
                                    <div class="agency-projects__content">
                                        <div class="post-overlay-content-inner">
                                            <div class="post-overlay-info">
                                                <h6 class="post-overlay-title">Aeroland app</h6>
                                                <h6 class="post-overlay-read-more">Read project <span class="far fa-long-arrow-right"></span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-12 wow move-up">
                            <div class="view-more-button text-center section-space--mt_40">
                                <a href="#" class="ht-btn ht-btn--outline ht-btn-default">View More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'ProjectAgency',
    };
</script>

<style lang="scss">

</style>