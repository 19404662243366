<template>
  <div
    class="tabs-wrapper section-space--pt_120 section-space--pb_60 bg-primary-500"
  >
    <div class="container-fluid--cp-120">
      <div class="row">
        <div class="col-lg-4">
          <slick class="slick1 tab-slider-nav" :options="slickOptions">
            <div class="tab-slider-nav--list wow move-up">
              <i class="ht-slider-tab-icon fal fa-align-left"></i>
              <h5 class="font-weight-700">Apps Móviles</h5>
              <p>
                Somos especialistas en la creación de <b>Aplicaciones Móviles.</b>
                Centrando tus ideas o proyectos en la experiencia final de los usuarios
              </p>
            </div>
            <div class="tab-slider-nav--list wow move-up">
              <i class="ht-slider-tab-icon fal fa-rocket"></i>
              <h5 class="font-weight-700">Diseño de tu identidad</h5>
              <p class="font-weight-600">
                Conocemos perfectamente como iniciar tu estrategia digital, y todo parte de tu identidad. Es por ello que nos especializamos en el Diseño UX / UI.
              </p>
            </div>
            <div class="tab-slider-nav--list wow move-up">
              <i class="ht-slider-tab-icon fal fa-chart-pie"></i>
              <h5 class="font-weight-700">E-Commerce</h5>
              <p class="font-weight-600">
                Te acompañamos en al construcción de tu tienda en línea, para que puedas potencializar tus ventas.
              </p>
            </div>
          </slick>
        </div>
        <div class="col-lg-8">
          <slick class="slick2" :options="slickOptions2">
            <div class="pro-large-img img-zoom wow move-up">
              <img
                class="img-fluid"
                src="../assets/img/banners/appMovile.png"
                alt="product-details"
              />
            </div>
            <div class="pro-large-img img-zoom wow move-up">
              <img
                class="img-fluid"
                src="../assets/img/banners/desarrolloWeb.png"
                alt="product-details"
              />
            </div>
            <div class="pro-large-img img-zoom wow move-up">
              <img
                class="img-fluid"
                src="../assets/img/banners/cms.png"
                alt="product-details"
              />
            </div>
          </slick>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'TabTwo',
  props: ['BgGradient'],
  components: {
    Slick,
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 3,
        focusOnSelect: true,
        vertical: true,
        asNavFor: '.slick2',
      },
      slickOptions2: {
        arrows: false,
        fade: true,
        dots: true,
        asNavFor: '.slick1',
      },
    }
  },
}
</script>
