<template>
  <div class="row pricing-table-two">
    <div
      class="col-md-6 col-lg-4 pricing-table pricing-table--popular wow move-up"
      v-for="(pricing, index) in pricingTable"
      :key="index"
    >
      <div class="pricing-table__inner">
        <div class="pricing-table__header">
          <h5 class="pricing-table__title font-weight-600">
            {{ pricing.title }}
          </h5>
          <div class="pricing-table__price-wrap">
            <h6 class="price">${{ pricing.value }}</h6>
          </div>
        </div>
        <div class="pricing-table__body">
          <div class="mt-3">
            <h6 class="text-color-neutral-100">Qué incluye este plan</h6>
          </div>
          <ul class="pricing-table__list text-left">
            <li v-for="(list, index) in pricing.price" :key="index">
              {{ list }}
            </li>
          </ul>
          <div class="mt-3" v-if="pricing.considerations.length == 1">
            <h6 class="text-color-neutral-100">Consideraciones</h6>
          </div>
          <ul
            class="pricing-table__list text-left"
            v-if="pricing.considerations.length == 1"
          >
            <li v-for="(list, index) in pricing.considerations" :key="index">
              {{ list }}
            </li>
          </ul>
          <div class="pricing-table__footer scroll">
            <a
              href="#contact"
              class="btn btn--accent-500"
              :class="{ 'btn--green': pricing.active }"
            >
              Solicita este plan
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { smoothScrollMixin } from '@/mixins/smoothscroll.mixin'
export default {
  name: 'pricingTwo',
  mixins: [smoothScrollMixin],
  data() {
    return {
      pricingTable: [
        {
          thumb: require('../assets/img/pricing/aeroland-pricing-image-06.png'),
          title: 'Página Web Estandar',
          value: '6,000.00 MXN',
          alt: 'Pricing Thumb',
          isPopular: true,
          active: true,
          price: [
            '4 Secciones.',
            'Dominio y Hosting incluido.',
            'Certificado de seguridad.',
            'Diseño de Gráfico.',
            '3 Cuentas de correo empresarial.',
            'Compatibilidad con dispositivos móviles.',
          
          ],
          considerations: [],
        },
        {
          thumb: require('../assets/img/pricing/aeroland-pricing-image-06.png'),
          title: 'Paquete Identidad Digital',
          value: '12,000.00 MXN',
          alt: 'Pricing Thumb',
          isPopular: true,
          active: true,
          price: [
            'Página web con 6 Secciones.',
            'Diseño de logotipo.',
            'Defición de paleta de colores y tipografías.',
            'Dominio y Hosting incluido.',
            'Certificado de seguridad.',
            'Cuentas de correo Ilimitadas.',
          
          ],
          considerations: [],
        },
        {
          thumb: require('../assets/img/pricing/aeroland-pricing-image-05.png'),
          title: 'Paquete E-Commerce',
          value: '16,000.00 MXN',
          alt: 'Pricing Thumb',
          isPopular: false,
          active: false,
          price: [
            '6 Secciones.',
            'Shopping Car',
            'Pagos con Tarjeta',
            'Dominio y Hosting incluido.',
            'Certificado de seguridad.',
            'Cuentas de correo empresarial ilimitadas.',
            'Administrador de contenidos.',
            'Compatibilidad con dispositivos móviles.',
          ],
          considerations: [],
        },
        
      ],
    }
  },
}
</script>
