<template>
    <div class="product-showcase-works-wrapper section-space--pb_120">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 wow move-up">
                    <div class="product-showcase-how-works">
                        <div class="section-title-wrapper section-space--mb_40">
                            <h3 class="font-weight--light text-black mb-20">How it works</h3>
                            <div class="sub_text">
                                This charger can work with a micro cable or work wireless via Bluetooth.
                            </div>
                        </div>
                        <div class="progress-bar--one section-space--mb_30">
                            <!-- Start Single Progress Charts -->
                            <div class="progress-charts">
                                <h6 class="heading ">Shock proof</h6>
                                <div class="progress">
                                    <div class="progress-bar gradient-2 wow fadeInLeft" data-wow-duration="0.7s" data-wow-delay=".5s" role="progressbar" style="width: 85%; visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: fadeInLeft;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">85%</span></div>
                                </div>
                            </div>
                            <!-- End Single Progress Charts -->
                            <!-- Start Single Progress Charts -->
                            <div class="progress-charts">
                                <h6 class="heading ">Classy colors</h6>
                                <div class="progress">
                                    <div class="progress-bar gradient-2 wow fadeInLeft" data-wow-duration="0.7s" data-wow-delay=".5s" role="progressbar" style="width: 72%; visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: fadeInLeft;" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">72%</span></div>
                                </div>
                            </div>
                            <!-- End Single Progress Charts -->
                            <!-- Start Single Progress Charts -->
                            <div class="progress-charts">
                                <h6 class="heading ">Highly durable</h6>
                                <div class="progress">
                                    <div class="progress-bar gradient-2 wow fadeInLeft" data-wow-duration="0.7s" data-wow-delay=".5s" role="progressbar" style="width: 89%; visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: fadeInLeft;" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">89%</span></div>
                                </div>
                            </div>
                            <!-- End Single Progress Charts -->
                        </div>
                        <div class="buy-now-button">
                            <button class="ht-btn ht-btn-md">$139 Buy now</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 wow move-up">
                    <div class="product-showcase-how-works-images">
                        <img class="img-fluid" src="../../assets/img/bg/product-showcase-image-02.jpg" alt="product thumb">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'ProductShowcaseWorkProgress',
    };
</script>