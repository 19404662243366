<template>
  <div
    class="footer-area-wrapper footer-style footer-background"
    :class="FooterStyle"
  >
    <div id="footer-area" class="footer-area section-space--ptb_90">
      <div class="container">
        <div class="row footer-widget-wrapper">
          <div class="col-lg-4 col-md-6 footer-widget">
            <div class="footer-widget__logo mb-30">
              <router-link to="/">
                <img
                  src="../assets/img/logo/img-logo-Footer.png"
                  class="img-fluid logo-dark"
                  alt="logo"
                />
                <img
                  src="../assets/img/logo/logo-light.png"
                  class="img-fluid logo-light"
                  alt="logo"
                />
              </router-link>
            </div>
            <ul class="footer-widget__list">
              <li>
                <a href="mailto:adrian.coria@leonix.com.mx" class="hover-style-link"
                  >adrian.coria@leonix.com.mx</a
                >
              </li>
              <li>
                <a href="tel:4431371292" class="hover-style-link"
                  >+52 (443) 137 1292</a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 footer-widget">
            <h6 class="footer-widget__title mb-20 footer-title">
              ¿Quienes somos?
            </h6>
            <ul class="footer-widget__list">
              <li>
                <b-nav-item
                  id="fotter-item-link-1"
                  href="#about"
                  class="scroll hover-style-link footer-link"
                  >Acerca de</b-nav-item
                >
              </li>
              <li>
                <b-nav-item
                  id="fotter-item-link-2"
                  href="#service"
                  class="scroll hover-style-link"
                  >Servicios</b-nav-item
                >
              </li>
              <li>
                <b-nav-item
                  id="fotter-item-link-3"
                  href="#faq"
                  class="scroll hover-style-link"
                  >Portafolio</b-nav-item
                >
              </li>
              <li>
                <b-nav-item
                  id="fotter-item-link-4"
                  href="#pricing"
                  class="scroll hover-style-link"
                  >Paquetes</b-nav-item
                >
              </li>
              <li>
                <b-nav-item
                  id="fotter-item-link-5"
                  href="#contact"
                  class="scroll hover-style-link"
                  >Contacto</b-nav-item
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 footer-widget">
            <h6 class="footer-widget__title mb-20 footer-title">Enlaces</h6>
            <ul class="footer-widget__list">
              <li @click="openModal">
                <router-link to="/" class="hover-style-link"
                  >Aviso de Privacidad</router-link
                >
              </li>
            </ul>
          </div>
          <!-- <div class="col-lg-3 col-md-6 offset-lg-1 footer-widget">
            <div class="twitter-feed-slider">
              <div class="swiper-container twitter-feed-slider__container">
                <div class="swiper-wrapper twitter-feed-slider__wrapper">
                  <div class="swiper-slide twitter-feed-slider__single">
                    <div class="tweet">
                      <div class="tweet__text">
                        "The ocean never ceases to amaze!" Feature: Ben Klea
                        <a href="https://t.co/jSRMsZAdPW" target="_blank"
                          >https://t.co/jSRMsZAdPW</a
                        >
                        <a href="https://t.co/2iDReuyPMt" target="_blank"
                          >https://t.co/2iDReuyPMt</a
                        >
                      </div>
                      <div class="tweet__info">
                        <h6 class="tweet__heading">Unsplash</h6>
                        <span class="tweet__date">May 07, 2021</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div
      id="footer-extension"
      class="footer-copyright-area section-space--ptb_30"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center text-md-left">
            <span class="copyright-text"> &copy; 2021 <b>Leonix</b> </span>
          </div>
          <div class="col-md-6 text-center text-md-right">
            <ul class="list ht-social-networks solid-rounded-icon">
              <li class="item">
                <a
                  href="https://www.facebook.com/leonixti/"
                  target="_blank"
                  class="social-link"
                >
                  <i class="fab fa-facebook social-link-icon"></i>
                </a>
              </li>
              <!-- <li class="item">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  class="social-link"
                >
                  <i class="fab fa-twitter social-link-icon"></i>
                </a>
              </li>
              <li class="item">
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  class="social-link"
                >
                  <i class="fab fa-instagram social-link-icon"></i>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMain',
  props: ['FooterStyle'],
  data: () => ({
    scrolled: false,
    lastPosition: 500,
    limitPosition: 200,
  }),
  mounted() {
    const f = () => {
      scrollTo()
    }
    f()

    function scrollTo() {
      const links = document.querySelectorAll('.scroll > a')
      links.forEach(each => (each.onclick = scrollAnchors))
    }

    function scrollAnchors(e, respond = null) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top)
      e.preventDefault()
      var targetID = respond
        ? respond.getAttribute('href')
        : this.getAttribute('href')
      const targetAnchor = document.querySelector(targetID)
      if (!targetAnchor) return
      const originalTop = distanceToTop(targetAnchor)
      window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' })
      const checkIfDone = setInterval(function() {
        const atBottom =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 190
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = '-1'
          targetAnchor.focus()
          clearInterval(checkIfDone)
        }
      }, 800)
    }
  },
  methods: {
    openModal() {
      this.$emit('openModal')
    },
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = true
        // move down
      }
      this.lastPosition = window.scrollY
      this.scrolled = window.scrollY > 50
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="sass">
@import "/src/assets/scss/_variabls"

$image-pattern: url("../assets/img/patterns/img-pattern-footer.png")
.footer-background
  background-image: $image-pattern

.footer-style
  background-color: $clr-primary-400

.hover-style-link
  color: $clr-neutral-100

#footer-area .hover-style-link:hover
  color: $clr-neutral-300 !important

.hover-style-link::after
  background-color: $clr-neutral-300 !important

.ht-social-networks.solid-rounded-icon .social-link:hover
  border: 1px solid $clr-neutral-300

.ht-social-networks.solid-rounded-icon .social-link:hover
  background: $clr-neutral-100

.ht-social-networks.solid-rounded-icon .social-link:hover
  color: $clr-primary-400

.ht-social-networks.solid-rounded-icon .social-link,
.footer-title
  color: $clr-neutral-100

#footer-extension
  padding-top: 10px
  padding-bottom: 10px

#footer-area
  padding-top: 50px
  padding-bottom: 50px

.copyright-text
  color: $clr-neutral-300

#fotter-item-link-1 > a:hover,
#fotter-item-link-2 > a:hover,
#fotter-item-link-3 > a:hover,
#fotter-item-link-4 > a:hover,
#fotter-item-link-5 > a:hover
  color: $clr-neutral-300 !important

#fotter-item-link-1 > a,
#fotter-item-link-2 > a,
#fotter-item-link-3 > a,
#fotter-item-link-4 > a,
#fotter-item-link-5 > a
  color: $clr-neutral-100
</style>
