<template>
    <div class="team-member-wrapper section-space--pt_120 section-space--pb_60">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center section-space--mb_60">
                        <h2 class="font-weight--light mb-15">The best team available</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/avatar-01.png" alt="image">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Chad Brewer</h5>
                                <div class="position">Director</div>
                                <div class="social-networks">
                                    <div class="inner">
                                        <a href="#" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i>
                                        </a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i>
                                        </a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/avatar-07.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Dollie Horton </h5>
                                <div class="position">Marketing</div>
                                <div class="social-networks">
                                    <div class="inner">
                                        <a href="#" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/avatar-05.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Fannie Garcia </h5>
                                <div class="position">Director</div>
                                <div class="social-networks">
                                    <div class="inner">
                                       <a href="#" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/avatar-04.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name"> Dollie Horton </h5>
                                <div class="position">Marketing</div>
                                <div class="social-networks">
                                    <div class="inner">
                                        <a href="#" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="#" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TeamOne',
    }
</script>
