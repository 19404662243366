<template>
    <div class="ebook-chanters-area ebook-chanters-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ebook-chanters-images text-center wow move-up">
                        <img src="../../assets/img/features/aeroland-ebook-image-03.jpg" class="img-fluid" alt="thumbnail">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EbookChanters',
    };
</script>