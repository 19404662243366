<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title-wrapper text-center section-space--mb_60">
          <h2 class="section-title underline mb-10 wow move-up">
            PORTAFOLIO
          </h2>
        </div>
      </div>
    </div>
    <div class="main-container">
      <div
        class="portfolio-pages-wrapper masonry-activation section-space--pt_100 section-space--pb_70 border-bottom"
      >
        <div class="container">
          <div
            class="row clearfix masonry-wrap"
            v-masonry
            transition-duration="3s"
            item-selector=".masonary-item"
          >
            <div
              class="col-lg-4 col-md-6 masonary-item wow move-up"
              v-for="portfolio in portfolios"
              :key="portfolio.id"
            >
              <div class="portfolio-wrapper mb-30">
                <a class="single-portfolio-item" :href="portfolio.alt" target="_blank">
                  <div class="single-portfolio__thumbnail">
                    <img
                      class="img-fluid border-radus-5"
                      :src="portfolio.image"
                      :alt="portfolio.alt"
                    />
                  </div>
                  <div
                    class="post-overlay gradient-background dark-background-porfolio"
                  ></div>
                  <div class="single-portfolio__content">
                    <div class="post-overlay-info" style="text-align:justify">
                      {{ portfolio.overlayTitle }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- portfolio item wrapper end -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    if (typeof this.$redrawVueMasonry === 'function') this.$redrawVueMasonry()
    this.onLoad()
  },
  data: () => ({
    portfolios: [
      {
        id: 1,
        image: require('@/assets/img/portfolio/leonix/logo-creditcar.png'),
        alt: 'https://creditcar.com.mx/',
        overlayTitle:
          'Creditcar es un sitio web, que ofrece servicios de préstamos con garantía vehicular. La página cuenta con un cotizador en línea integrado con el Libro Azul.',
      },
      {
        id: 2,
        image: require('@/assets/img/portfolio/leonix/logo-cypress.png'),
        alt: 'https://cypressinmobiliaria.com/',
        overlayTitle:
          'Cypress Inmobiliaria es una página web, que tiene como finalidad realizar la promoción de venta y renta de inmuebles en la ciudad de Morelia.',
      },
      {
        id: 3,
        image: require('@/assets/img/portfolio/leonix/logo-maya.png'),
        alt: 'https://www.dypmaya.com.mx/',
        overlayTitle:
          'Maya, es una página web, que oferta los servicios de diseño gráfico de esta empresa, cuenta con un cotizador en línea que permite a los clientes estimar los precios de los productos.',
      },
      {
        id: 4,
        image: require('@/assets/img/portfolio/leonix/logo-colegio.png'),
        alt: 'https://www.colegiodenotariosmichoacan.org.mx',
        overlayTitle:
          'El sitio del colegio de notarios, es una página formal que presenta información general del Colegio, Eventos principales realizados, leyes vigentes de impacto a los notarios y un apartado de Directorio, mediante el cual los usuarios pueden identificar todas las notarías del estado de Michoacán.',
      },
      {
        id: 5,
        image: require('@/assets/img/portfolio/leonix/logo-flirty.png'),
        alt: 'https://flirtystudio.com/',
        overlayTitle:
          'El sitio web de FlirtyStudio, es una página que promueve los servicios de belleza de una profesional de USA.',
      },
      {
        id: 6,
        image: require('@/assets/img/portfolio/leonix/logo-yomaira.png'),
        alt: 'https://www.usamicrobladingcourses.com/',
        overlayTitle:
          'Un sitio E-Commerce que ofrece la venta de curso y productos en Línea, integrado con guías de entrega',
      },
    ],
  }),
  methods: {
    async onLoad() {
      await this.sleep(950)
      this.$redrawVueMasonry()
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
}
</script>

<style lang="sass">
@import "/src/assets/scss/_variabls"

.single-portfolio-item
 text-align: center

.post-overlay.gradient-background.dark-background-porfolio
  background-color: $clr-primary-400
  background-image: linear-gradient(-129deg, $clr-primary-500 0, $clr-primary-500 100%)
</style>
``
