<template>
    <div class="payment-staps-way" id="service">
        <div class="payment_feature_svg vc_row-separator tilt_right top">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 4 0.266661">
                <linearGradient id="svg-fill-5d3a79b9beb1f">
                    <stop offset="0%" stop-color="#9c7af2"></stop>
                    <stop offset="100%" stop-color="#5e61e7"></stop>
                </linearGradient>
                <polygon points="4,0 4,0.266661 -0,0.266661" fill="url( #svg-fill-5d3a79b9beb1f )"></polygon>
            </svg>
        </div>
        <div class="payment-typed-text-wrap" id="sevice">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 wow move-up">
                        <div class="section-title-wrapper text-center section-space--mb_60">
                            <h2 class="section-title text-white font-width-light mb-15  wow move-up">Say hello to auto process</h2>
                            <p class="text-white wow move-up">Financial transactions turn from physical to digital.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment-staps-way-wrap section-space--pt_60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- payment Process Step One Start -->
                        <div class="payment-process-step__one wow move-up">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="payment-process-step__media text-center">
                                        <img src="../../assets/img/features/aeroland-payment-image-01.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="payment-process-step__content float-md-right">
                                        <div class="payment-process__inner">
                                            <h4 class="font-weight--blod section-space--mb_40">Effortless billing with AeroLand
                                                <sup>01</sup>
                                            </h4>
                                            <div class="sub-text section-space--mb_40">
                                                Enhanced billing systems offer you a variety of ways to pay electric bills, phone bills, medical bills delivered online, in person or by mail.
                                            </div>
                                            <div class="payment-process-btn section-space--mb_40">
                                                <a href="#" class="ht-btn ht-btn-md"><i class="fa fa-arrow-alt-to-bottom mr-2"></i> Try for free</a>
                                                <h6 class="sub-heading">Questions? <a href="#" class="hover-style-link hover-style-link--green">Talk to our team</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- payment Process Step One End -->

                        <!-- payment Process Step Two Start -->
                        <div class="payment-process-step__two section-space--pt_60 wow move-up">
                            <div class="row align-items-center">
                                <div class="col-lg-5 order-2 order-lg-1">
                                    <div class="payment-process-step__content float-md-left">
                                        <div class="payment-process__inner">
                                            <h4 class="font-weight--blod section-space--mb_40">Secure payment
                                                <sup>02</sup>
                                            </h4>
                                            <ul class="check-list green-check-list section-space--mt_30">
                                                <li class="list-item">User-friendly, modern and intuitive</li>
                                                <li class="list-item">Updated mobile design & features </li>
                                                <li class="list-item">Beautiful icons, typography and images </li>
                                                <li class="list-item">Extendable premium customer support </li>
                                            </ul>
                                            <div class="payment-process-btn section-space--mt_40">
                                                <a href="#" class="ht-btn ht-btn-md"> Find out more <i class="far fa-long-arrow-right ml-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 order-1 order-lg-2">
                                    <div class="app-showcase-process-step__media text-center">
                                        <img src="../../assets/img/features/aeroland-payment-image-02.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- payment Process Step End Start -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PaymentStep',
    };
</script>