<template>
    <div class="row pricing-table-four">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 pricing-table pricing-table--popular wow move-up">
            <div class="pricing-table__inner">
                <div class="pricing-table__top">
                    <div class="pricing-table__header">
                        <div class="pricing-table__image">
                            <img src="../assets/img/pricing/aeroland-pricing-05-image-01.png" class="img-fluid" alt="">
                        </div>
                        <h5 class="pricing-table__title">Classic package</h5>
                    </div>
                    <div class="pricing-table__body">
                        <ul class="pricing-table__list text-center">
                            <li>03 projects</li>
                            <li>Power And Predictive Dialing </li>
                            <li>Quality &amp; Customer Experience</li>
                        </ul>
                    </div>
                </div>
                <div class="pricing-table__footer">
                    <a href="#" class="hover-style-link hover-style-link--green">Get started<span class="btn-icon"><i class="far fa-long-arrow-right"></i><div></div></span></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 pricing-table pricing-table--popular wow move-up">
            <div class="pricing-table__inner">
                <div class="pricing-table__top">
                    <div class="pricing-table__header">
                        <div class="pricing-table__image">
                            <img src="../assets/img/pricing/aeroland-pricing-05-image-02.png" class="img-fluid" alt="">
                        </div>
                        <h5 class="pricing-table__title">Advance package</h5>
                    </div>
                    <div class="pricing-table__body">
                        <ul class="pricing-table__list text-center">
                            <li>10 projects </li>
                            <li>Power And Predictive Dialing </li>
                            <li>Quality &amp; Customer Experience</li>
                            <li>24/7 phone and email support </li>
                        </ul>
                    </div>
                </div>
                <div class="pricing-table__footer">
                    <a href="#" class="hover-style-link hover-style-link--green">Get started<span class="btn-icon"><i class="far fa-long-arrow-right"></i><div></div></span></a>
                </div>

            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 pricing-table pricing-table--popular wow move-up">
            <div class="pricing-table__inner">
                <div class="pricing-table__top">
                    <div class="pricing-table__header">
                        <div class="pricing-table__image">
                            <img src="../assets/img/pricing/aeroland-pricing-05-image-01.png" class="img-fluid" alt="">
                        </div>
                        <h5 class="pricing-table__title">Plus package</h5>
                    </div>
                    <div class="pricing-table__body">
                        <ul class="pricing-table__list text-center">
                            <li>50 projects</li>
                            <li>Power And Predictive Dialing </li>
                            <li>Quality &amp; Customer Experience</li>
                        </ul>
                    </div>
                </div>
                <div class="pricing-table__footer">
                    <a href="#" class="hover-style-link hover-style-link--green">Get started<span class="btn-icon"><i class="far fa-long-arrow-right"></i><div></div></span></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'pricingFour',
    }
</script>