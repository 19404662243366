<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title-wrapper text-center section-space--mb_60">
          <h2
            class="section-title font-weight--light mb-10 wow move-up underline"
          >
            Caso de éxito
          </h2>
        </div>
      </div>
    </div>
    <div class="portfolio-details-wrapper section-space--ptb_100">
      <div class="container">
        <div class="row portfolio-details-feature-wrap">
          <div class="col-lg-6">
            <div class="portfolio-details-one-images mb-30">
              <img
                class="img-fluid border-radus-5"
                src="@/assets/img/portfolio/leonix/img-csosExito.png"
                alt="image"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="portfolio-details-content">
              <div class="portfolio-details-title">
                <h3 class="portfolio-details-title font-weight-800">
                  Sistema Financiero CreditCar
                </h3>
              </div>
              <div class="text mt-20 text-justify font-weight-700">
                <p>
                  Creditcar es una <b>aplicación web</b>, desarrollada con tecnologia
                  de última generación, que tiene como finalidad controlar y
                  <b>administrar el proceso de crédito en sus etapas de evaluación,
                  autorización, instrumentación y desembolso.</b> Cuenta con módulos
                  de pagos, cobranza preventiva, facturación, administración de
                  usuarios y control de expedientes.
                </p>
                <p class="text-justify">
                  Esta aplicación a tenido un <b>impacto importante en la empresa
                  Creditcar</b>, los cuales <b>pasaron de un esquema de atención y
                  administrativo de forma manual</b>, a un esquema totalmente
                  automatizado, beneficiando en sus tiempos de respuesta y
                  mejorando el control interno de sus contratos de crédito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="portfolio-details-wrapper section-space--ptb_100">
      <div class="container">
        <div class="row portfolio-details-feature-wrap">
          <div class="col-lg-6">
            <div class="portfolio-details-one-images mb-30">
              <img
                class="img-fluid border-radus-5"
                src="@/assets/img/portfolio/leonix/creditScar.png"
                alt="image"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="portfolio-details-content">
              <div class="portfolio-details-title">
                <h3 class="portfolio-details-title font-weight-800">
                  Sistema de Empeños PrestaMex
                </h3>
              </div>
              <div class="text mt-20 font-weight-700">
                <p>
                  Prestamex es una aplicación web basada en Java, que permite la
                  administración de los procesos de las casas de empeño. Entre
                  las funciones principales se encuentran: Empeños, refrendos,
                  abonos, recargos, remates, compras, ventas, facturación,
                  reportes etc.
                </p>
                <p>
                  Esta aplicación ha permitido operar a más de 16 sucursales en
                  Michoacán, y es el core de su negocio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {}
</script>

<style></style>
