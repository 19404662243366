<template>
    <div class="pricing-table-area section-space--ptb_120 theme_bg-2" id="pricing">
        <div class="pricing-table-content-area">
            <div class="container-fluid container-fluid--cp-150">
                <div class="row">
                    <div class="col-lg-3 col-md-12">
                        <div class="price-plan-wrap mt-30 section-space--mb_60">
                            <div class="price-plan-title mb-30 wow move-up">
                                <h3 class="heading text-white">Great price<br>plan for you!</h3>
                            </div>
                            <ul class="check-list price-plan-list mb-30 wow move-up">
                                <li class="list-item">Aenean volutpat felis at libero </li>
                                <li class="list-item">Aenean volutpat felis at libero </li>
                                <li class="list-item">Aenean volutpat felis at libero </li>
                            </ul>
                            <h6 class="sub-heading wow move-up">What’s next for AeroLand? <a href="#" class="hover-style-link hover-style-link--green">Learn more</a></h6>
                        </div>


                    </div>
                    <div class="col-lg-9 col-md-12">
                        <div class="row pricing-table-three">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <div class="pricing-table__image">
                                            <img src="../assets/img/pricing/aeroland-pricing-image-06.png" class="img-fluid" alt="">
                                        </div>
                                        <h5 class="pricing-table__title">Free</h5>
                                    </div>
                                    <div class="pricing-table__body">
                                        <p>Lorem ipsum dolor sit amet, consectetur elit. Suspe ndisse suscipit sagittis leo sitea rem ipsum dolor sit amet, consectetu.</p>
                                    </div>
                                    <div class="pricing-table__footer">
                                        <a href="#" class="ht-btn ht-btn-default">Get started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pricing-table pricing-table--popular wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__feature-mark">
                                        <span>Popular Choice</span>
                                    </div>
                                    <div class="pricing-table__header">
                                        <div class="pricing-table__image">
                                            <img src="../assets/img/pricing/aeroland-pricing-image-05.png" class="img-fluid" alt="">
                                        </div>
                                        <h5 class="pricing-table__title">Personal</h5>
                                    </div>
                                    <div class="pricing-table__body">
                                        <p>Lorem ipsum dolor sit amet, consectetur elit. Suspe ndisse suscipit sagittis leo sitea rem ipsum dolor sit amet, consectetu.</p>
                                    </div>
                                    <div class="pricing-table__footer">
                                        <a href="#" class="ht-btn ht-btn-default">Get started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <div class="pricing-table__image">
                                            <img src="../assets/img/pricing/aeroland-pricing-image-07.png" class="img-fluid" alt="">
                                        </div>
                                        <h5 class="pricing-table__title">Group</h5>
                                    </div>
                                    <div class="pricing-table__body">
                                        <p>Lorem ipsum dolor sit amet, consectetur elit. Suspe ndisse suscipit sagittis leo sitea rem ipsum dolor sit amet, consectetu.</p>
                                    </div>
                                    <div class="pricing-table__footer">
                                        <a href="#" class="ht-btn ht-btn-default">Get started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PricingThree',
    }
</script>