<template>
    <div class="attribute-wrapper section-space--pt_120" id="about">
        <div class="container_fl">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="single-attribute col-06__left">
                        <div class="section-title-wrapper section-space--mb_60">
                            <h3 class="font-weight--light text-black section-space--mb_30 wow move-up">A charger for you in an emergency. Also a proficient data transmitter.</h3>
                            <div class="sub-text wow move-up">
                                Join the 30 million+ powered by our leading technology. This is the successor to PowerCore 10400—identical compact size but packed with even more power. Charges an iPhone 8 almost 5 times, an iPhone X or Samsung Galaxy S9 3 times, or an iPad Air 2 once.
                            </div>
                        </div>
                        <ul class="attribute_list_one  section-space--mb_50 wow move-up">
                            <li class="item">
                                <div class="name">
                                    <h6>Size</h6>
                                </div>
                                <div class="value">70mm x70mm x 25mm</div>
                            </li>
                            <li class="item">
                                <div class="name">
                                    <h6>Weight</h6>
                                </div>
                                <div class="value">196g</div>
                            </li>
                            <li class="item">
                                <div class="name">
                                    <h6>Materials</h6>
                                </div>
                                <div class="value">Aluminium / textile / Rubber /<br>Polymer / Foam / Silicone / Nylon</div>
                            </li>
                            <li class="item">
                                <div class="name">
                                    <h6>Frequeency</h6>
                                </div>
                                <div class="value">20 - 20,000 Hz</div>
                            </li>
                        </ul>
                        <h6 class="text-black wow move-up">What’s next in AeroLand? <a href="#" class="hover-style-link hover-style-link--green">Learn more</a></h6>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="attribute-image text-right wow move-up">
                        <img class="img-fluid" src="../../assets/img/banners/product-showcase.jpg" alt="about">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'AboutProductShowcase',
    };
</script>