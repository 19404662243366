<template>
  <div class="feature-icon-list">
    <div class="row">
      <div class="col-lg-6">
        <div class="feature-icon-list__media wow move-up">
          <img
            src="../assets/img/features/img-quienes-somos.png"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="feature-icon-list__content">
          <div class="feature-icon-list-wrapper">
            <div
              v-for="feature in features"
              :key="feature.id"
              class="single-feature-icon wow move-up"
            >
              <div class="single-feature-icon__media">
                <img :src="feature.icon" class="img-fluid" alt="" />
              </div>
              <div class="single-feature-icon__content">
                <h6 class="title">{{ feature.title }}</h6>
                <div class="text">{{ feature.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'featureList',
  data() {
    return {
      features: [
        {
          icon: require('../assets/img/icons/aeroland-branding-box-image-01-1.png'),
          title: 'Nuestra Visión',
          desc:
            'Ser la agencia más representativa de la ciudad de Morelia, y extender sus servicios a nivel Regional y N acional.',
        },
        // {
        //   icon: require('../assets/img/icons/aeroland-branding-box-image-02-1.png'),
        //   title: 'Nuestros clientes',
        //   desc:
        //     'En Leonix trabajamos de la mano con nuestros clientes en el diseño y construcción de nuestras aplicaciones. Nuestro cliente es la pieza dentro del equipo.',
        // },
        // {
        //   icon: require('../assets/img/icons/aeroland-branding-box-image-03-1.png'),
        //   title: 'Nuestras Tecnologías',
        //   desc:
        //     'En Leonix utilizamos tecnologías modernas, innovadoras y de gran rendimiento, esto en busca de crear la mejor experiencia de usuario, en el uso de nuestras aplicaciones.',
        // },
      ],
    }
  },
}
</script>
