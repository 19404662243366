<template>
    <div class="ebook-mailchimp-area  section-space--ptb_120">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 ml-auto mr-auto">
                    <div class="ebook-mailchip-title text-center section-space--mb_50">
                        <h6 class="heading">Enter your email <mark>to get free chapter-01</mark></h6>
                    </div>
                    <div class="ebook-mailchip">
                        <div class="newsletter-form--ebook text-center">
                            <form>
                                <input type="email" placeholder="Enter your email">
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EbookMailchimp',
    };
</script>