<template>
    <div class="clinic-work-step-wrap section-space--ptb_120" id="works">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title-wrapper text-center section-space--pb_90">
                        <h6 class="section-sub-title mb-20 font-weight--bold wow move-up">How AeroLand works?</h6>
                        <h2 class="section-title font-weight--blod wow move-up">AeroLand helps you find the right help</h2>
                    </div>
                </div>
            </div>
            <!-- clinic Process Step first Start -->
            <div class="clinic-work-process-step ">
                <div class="row">
                    <div class="col-lg-6 wow move-up">
                        <div class="clinic-work-step__media images-left-ml  text-center">
                            <img src="../../assets/img/features/aeroland-clinic-image-01.png" class="img-fluid" alt="thumbnail">
                        </div>
                    </div>
                    <div class="col-lg-5 wow move-up">
                        <div class="clinic-work-process-step__content">
                            <div class="app-showcase__inner">
                                <div class="sub__title mb-20">
                                    <h6 class="font-weight--blod">Step 01</h6>
                                </div>
                                <h4 class="font-weight--bold">Instant appointment <br> with doctors</h4>
                                <div class="sub-text section-space--mb_40">
                                    We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service. People can enroll membership by paying $129.
                                </div>
                                <div class="btn-sowcase section-space--mb_40">
                                    <a href="#" class="ht-btn ht-btn-md">Find out more <span class="btn-icon"><i class="far fa-long-arrow-right"></i></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- clinic Process Step first End -->
            <!-- clinic Process Step second Start -->
            <div class="clinic-work-process-step section-space--pt_90">
                <div class="row">
                    <div class="col-lg-6 order-lg-1 order-2 wow move-up">
                        <div class="clinic-work-process-step__content">
                            <div class="app-showcase__inner">
                                <div class="sub__title mb-20">
                                    <h6 class="font-weight--blod">Step 02</h6>
                                </div>
                                <h4 class="font-weight--bold">First-rate Healthcare <br> Providers</h4>
                                <ul class="check-list green-check-list section-space--mt_40">
                                    <li class="list-item">Dedicated, passionate and comfortable services </li>
                                    <li class="list-item">Affordable pricing plans for individuals </li>
                                    <li class="list-item">Premium customer support </li>
                                    <li class="list-item">Delivery time is accurate and scheduled </li>
                                </ul>
                                <div class="btn-sowcase section-space--mt_40">
                                    <a href="#" class="ht-btn ht-btn-md">Find out more <span class="btn-icon"><i class="far fa-long-arrow-right"></i></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 order-lg-2 order-1 wow move-up">
                        <div class="clinic-work-step__media images-right-mr  text-center">
                            <img src="../../assets/img/features/aeroland-clinic-image-02.png" class="img-fluid" alt="thumbnail">
                        </div>
                    </div>

                </div>
            </div>
            <!-- clinic Process Step second End -->
            <!-- clinic Process Step Three Start -->
            <div class="clinic-work-process-step  section-space--pt_90">
                <div class="row">
                    <div class="col-lg-6 wow move-up">
                        <div class="clinic-work-step__media images-left-ml  text-center">
                            <img src="../../assets/img/features/aeroland-clinic-image-03.png" class="img-fluid" alt="thumbnail">
                        </div>
                    </div>
                    <div class="col-lg-6 wow move-up">
                        <div class="clinic-work-process-step__content">
                            <div class="app-showcase__inner">
                                <div class="sub__title mb-20">
                                    <h6 class="font-weight--blod">Step 03</h6>
                                </div>
                                <h4 class="font-weight--bold">Introduct App for AeroLand</h4>
                                <div class="sub-text section-space--mb_40">
                                    There’s no need to be a technical professional to create sites with AeroLand. With simple guides, everything is easy! We deliver the best services for app design and coding.
                                </div>
                                <div class="btn-sowcase">
                                    <div class="button-group-wrap">
                                        <a href="#" class="image-btn img-fluid mr-2"><img src="../../assets/img/icons/aeroland-black-button-google-play.jpg" alt="thumbnail"></a>
                                        <a href="#" class="image-btn img-fluid"><img src="../../assets/img/icons/aeroland-button-app-store-dark.jpg" alt="thumbnail"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- clinic Process Step Three End -->
        </div>
    </div>
</template>

<script>
    export default{
        name: 'ClinicWorkStep',
    };
</script>