<template>
    <div class="progress-bar--one">
        <!-- Start Single Progress Charts -->
        <div class="progress-charts wow move-up">
            <h6 class="heading ">UI/UX</h6>
            <div class="progress">
                <div class="progress-bar gradient-1" role="progressbar" style="width: 81%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">81%</span></div>
            </div>
        </div>
        <!-- End Single Progress Charts -->

        <!-- Start Single Progress Charts -->
        <div class="progress-charts wow move-up">
            <h6 class="heading ">Ideas</h6>
            <div class="progress">
                <div class="progress-bar gradient-2" role="progressbar" style="width: 72%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">72%</span></div>
            </div>
        </div>
        <!-- End Single Progress Charts -->

        <!-- Start Single Progress Charts -->
        <div class="progress-charts wow move-up">
            <h6 class="heading ">Marketing</h6>
            <div class="progress">
                <div class="progress-bar gradient-3" role="progressbar" style="width: 89%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">89%</span></div>
            </div>
        </div>
        <!-- End Single Progress Charts -->

        <!-- Start Single Progress Charts -->
        <div class="progress-charts wow move-up">
            <h6 class="heading ">Consulting</h6>
            <div class="progress">
                <div class="progress-bar gradient-4" role="progressbar" style="width: 57%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"><span class="percent-label">57%</span></div>
            </div>
        </div>
        <!-- End Single Progress Charts -->
    </div>
</template>

<script>
    export default {
        name: 'ProgressBar',
    };
</script>