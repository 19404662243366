<template>
    <div class="icon-process-wrapper">
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-fire"></i>
                </div>
                <h5 class="icon-process-single__title">Define your ideas</h5>
                <div class="icon-process-single__text">
                    Share ideas of how you would like your app to perform to achieve goals.
                </div>
            </div>
        </div>
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-users"></i>
                </div>
                <h5 class="icon-process-single__title">Work on designs</h5>
                <div class="icon-process-single__text">
                    After getting your brief, our experts work on crafting the final designs.
                </div>
            </div>
        </div>
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-chart-line"></i>
                </div>
                <h5 class="icon-process-single__title">Coding and Review</h5>
                <div class="icon-process-single__text">
                    The final product is obtained from meticulous coding and review.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'Gradation',
    };
</script>
