<template>
    <div class="row">
        <div class="col-lg-6 video-popup video-link mt-30">
            <silentbox-single src="https://www.youtube.com/watch?v=9No-FiEInLA">
                <div class="single-popup-wrap">
                    <img class="img-fluid" src="../assets/img/bg/testimonial-bg-570x420.jpg" alt="">
                    <div class="ht-popup-video video-button video-overlay">
                        <div class="video-mark">
                            <div class="wave-pulse wave-pulse-1"></div>
                            <div class="wave-pulse wave-pulse-2"></div>
                        </div>
                        <div class="video-button__five">
                            <div class="video-play">
                                <span class="video-play-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </silentbox-single>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PopUpVideoThree',
    };
</script>