<template>
    <div class="app-landing-brand-logo-area section-space--ptb_30 drak-area theme_bg-2" id="partner">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="brand-details-wrap">
                        <h4 class="heading mb-20  wow move-up">We’ve established partnership with partners all over the globe.</h4>
                        <p class="text section-space--mb_40 wow move-up">We’ve made the negotiation with impact-first investors dedicated to expanding opportunities for small-sized businesses to make loans and early stage investments.</p>
                        <router-link to="/app-landing" class="ht-btn ht-btn-md">View all clients</router-link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row brand-logo-wrapper">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6 brand-logo wow move-up">
                            <div class="app-landing-brand-logo__image">
                                <a href="#"><img src="../assets/img/brand/aeroland-app-landing-client-logo-01.png" class="img-fluid" alt=""></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6 brand-logo wow move-up">
                            <div class="app-landing-brand-logo__image">
                                <a href="#"><img src="../assets/img/brand/aeroland-app-landing-client-logo-02.png" class="img-fluid" alt=""></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6 brand-logo wow move-up">
                            <div class="app-landing-brand-logo__image">
                                <a href="#"><img src="../assets/img/brand/aeroland-app-landing-client-logo-03.png" class="img-fluid" alt=""></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6 brand-logo wow move-up">
                            <div class="app-landing-brand-logo__image">
                                <a href="#"><img src="../assets/img/brand/aeroland-app-landing-client-logo-04.png" class="img-fluid" alt=""></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6 brand-logo wow move-up">
                            <div class="app-landing-brand-logo__image">
                                <a href="#"><img src="../assets/img/brand/aeroland-app-landing-client-logo-05.png" class="img-fluid" alt=""></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6 brand-logo wow move-up">
                            <div class="app-landing-brand-logo__image">
                                <a href="#"><img src="../assets/img/brand/aeroland-app-landing-client-logo-06.png" class="img-fluid" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Partnership',
    };
</script>