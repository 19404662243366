import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import VueSilentbox from 'vue-silentbox'
import { VueMasonryPlugin } from 'vue-masonry'
import WOW from 'wow.js/dist/wow.js'
import VueMeta from 'vue-meta'
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueMasonryPlugin)
Vue.use(VueSilentbox)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})
Vue.use(VueSmoothScroll)

import VueSweetalert2 from 'vue-sweetalert2';
import 'bootstrap/dist/css/bootstrap.css'
import '../src/assets/css/fontawesome-all.min.css'
import 'swiper/swiper.scss'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'slick-carousel/slick/slick.css'

import 'sweetalert2/dist/sweetalert2.min.css';
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'

const options = {
  confirmButtonColor: '#94692a',
  cancelButtonColor: '#f3f3f2',
};

Vue.use(Vuelidate)
Vue.use(VueSweetalert2, options);
Vue.use(VueMask);

Vue.config.productionTip = false

import { routes } from './router/index'

const router = new VueRouter({
  routes,
  mode: 'history',
})

new Vue({
  render: h => h(App),
  router,
  mounted() {
    new WOW().init()
  },
}).$mount('#app')
