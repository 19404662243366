<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h3>Acerca de Nosotros</h3>
          </div>
          <div class="modal-body">
            <h5>Misión</h5>
            <p style="margin-top: 20px;">
              Somos una agencia de desarrollo de software, enfocada a ofrecer
              servicios de tecnología de <b>alta calidad y velocidad</b>,
              buscando ser un aliado tecnológico en las estrategias digitales de
              nuestros clientes.
            </p>
            <br />
            <h5>Visión</h5>
            <p style="margin-top: 20px;">
              Ser una agencia de desarrollo de software reconocida a nivel
              nacional, por la calidad, velocidad e innovación de nuestros
              servicios digitales.
            </p>
            <br />
            <h5>Valores</h5>
            <ul
              class="valores-list"
              style="margin-top: 20px; list-style-type:disc;"
            >
              <li>
                <b>Calidad</b>. Desarrollamos software confiable, estable y
                seguro para nuestros clientes.
              </li>
              <li>
                <b>Responsabilidad</b>. Asumimos compromisos con nuestros
                clientes y cumplirlos en tiempo y forma.
              </li>
              <li>
                <b>Honestidad</b>. Actuamos con transparencia, honradez e
                integridad.
              </li>
              <li>
                <b>Agilidad</b>. Aportamos velocidad y adaptabilidad a cada uno
                de nuestros desarrollos.
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              class="modal-default-button btn btn--primary-500"
              type="button"
              @click="close"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="sass" scoped>
@import "/src/assets/scss/_variabls"

.modal-default-button
  background-color: $clr-accent-500
</style>
<style scoped>
.valores-list > li {
  margin-left: 30px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(00, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
  max-height: 60vh;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
