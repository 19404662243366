<template>
  <div class="hero-software hero-swiper-btn" id="home">
    <div class="container_f1">
      <div class="swiper-wrapper">
        <swiper :options="swiperOption" :navigation="true">
          <div class="swiper-slide" :style="bgImg">
            <div class="hero-item">
              <div class="hero-content scroll">
                <h2 class="h1 hero-content-title">Crea tu Identidad Digital</h2>
                <h6 class="hero-content-subtitle">
                  Te apoyamos desde <b>CERO.</b> Creamos tu logo, página WEB e
                  identidad para incorporarte a la era <b>Digital.</b>
                </h6>
                <a href="#contact" class="btn btn--accent-300">
                  <span class="btn-icon ml-0 mr-2"
                    ><i class="fa fa-arrow-alt-to-bottom"></i
                  ></span>
                  Te asesoramos sin costo
                </a>
              </div>
              <div class="hero-thumb">
                <img
                  src="../assets/img/hero/hero-software/img-banner-consultoria-Digital.png"
                  class="img-fluid"
                  alt="hero thumb"
                />
              </div>
            </div>
          </div>
          <div class="swiper-slide" :style="bgImg">
            <div class="hero-item">
              <div class="hero-content scroll">
                <h2 class="h1 hero-content-title">Apps WEB y Móviles</h2>
                <h6 class="hero-content-subtitle">
                  Dale a tus clientes o negocio, la movilidad que se merecen.
                  Desarrollamos soluciones <b>WEB y Móviles Innovadoras</b> para
                  introducirte en el mundo digital
                </h6>
                <a href="#contact" class="btn btn--accent-300">
                  <span class="btn-icon ml-0 mr-2"
                    ><i class="fa fa-arrow-alt-to-bottom"></i
                  ></span>
                  Te asesoramos sin costo
                </a>
              </div>
              <div class="hero-thumb pr-100">
                <img
                  src="../assets/img/hero/hero-software/leonix-desarrollo-Web.jpg"
                  class="img-fluid"
                  alt="hero thumb"
                />
              </div>
            </div>
          </div>
          <div class="swiper-slide" :style="bgImg">
            <div class="hero-item">
              <div class="hero-content scroll">
                <h2 class="h1 hero-content-title">
                  Vende en Línea
                </h2>
                <h6 class="hero-content-subtitle">
                  <b>Diseñamos, personalizamos y desarrollamos</b> tu tienda para vender en línea. Al finalizar <b>te capacitamos</b> para que seas un experto en la venta. 
                </h6>
                <a href="#contact" class="btn btn--accent-300">
                  <span class="btn-icon ml-0 mr-2"
                    ><i class="fa fa-arrow-alt-to-bottom"></i
                  ></span>
                  Te asesoramos sin costo
                </a>
              </div>
              <div class="hero-thumb pr-100">
                <img
                  src="../assets/img/hero/hero-software/leonix-Pymes.jpg"
                  class="img-fluid"
                  alt="hero thumb"
                />
              </div>
            </div>
          </div>
        </swiper>
        <!-- Optional controls -->
        <div class="swiper-button-prev-1 btn-prev">
          <i class="far fa-angle-left far-icon"></i>
        </div>
        <div class="swiper-button-next-1 btn-next">
          <i class="far fa-angle-right far-icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper.scss'

import 'swiper/components/navigation/navigation.min.css'

import SwiperCore, { Navigation, Autoplay } from 'swiper/core'
import { smoothScrollMixin } from '@/mixins/smoothscroll.mixin'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay])

export default {
  mixins: [smoothScrollMixin],
  data() {
    return {
      bgImg: {
        backgroundImage: `url(${require('../assets/img/hero/hero-software/software-shape.png')})`,
      },
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: 'fade',
        autoplay: {
          enabled: true,
          delay: 8000,
        },
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next-1',
          prevEl: '.swiper-button-prev-1',
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/variabls.scss';
@import '../assets/scss/elements/hero-software.scss';

.btn-prev,
.btn-next {
  z-index: 1000;
  position: absolute;
  top: 45%;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-prev:hover,
.btn-next:hover {
  background-color: $clr-accent-300;
}

.btn-prev {
  left: 1vw;
}

.btn-next {
  right: 1vw;
}

.far-icon {
  font-weight: 400;
  font-size: 50px;
  line-height: 75px;

  color: whitesmoke;
}
</style>
