export const smoothScrollMixin = {
  data() {
    return {
      load: false,
      limitPosition: 200,
      scrolled: false,
      lastPosition: 500,
    }
  },
  mounted() {
    const f = () => {
      scrollTo()
    }
    f()

    function scrollTo() {
      const links = document.querySelectorAll('.scroll > a')
      links.forEach(each => (each.onclick = scrollAnchors))
    }

    function scrollAnchors(e, respond = null) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top)
      e.preventDefault()
      var targetID = respond
        ? respond.getAttribute('href')
        : this.getAttribute('href')
      const targetAnchor = document.querySelector(targetID)
      if (!targetAnchor) return
      const originalTop = distanceToTop(targetAnchor)
      window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' })
      const checkIfDone = setInterval(function() {
        const atBottom =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 190
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = '-1'
          targetAnchor.focus()
          clearInterval(checkIfDone)
        }
      }, 800)
    }
  },
  methods: {
    // sticky menu script
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = true
        // move down
      }
      this.lastPosition = window.scrollY
      this.scrolled = window.scrollY > 50
    },

    // offcanvas searchbox
    toggleClass(addRemoveClass, className) {
      const el = document.querySelector('#search-overlay')
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
