<template>
    <div class="feature-app-landing drak-area section-space--ptb_60" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 wow move-up">
                    <div class="feature-app-landing__wrap">
                        <div class="feature-app-landing__inner ">
                            <div class="section-title">
                                <h2>A Collection of Prominent Features</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 wow move-up">
                    <div class="feature-app-landing__wrap">
                        <div class="feature-app-landing__inner">
                            <div class="feature-app-landing__icon">
                                <div class="icon">
                                    <i class="fa fa-bell"></i>
                                </div>
                            </div>
                            <div class="feature-app-landing__content">
                                <h5 class="heading mb-10">Web Development</h5>
                                <div class="text">Our technical experts have a flair for developing clean-coded &amp; seamless websites based on customers’ needs, global guidelines and trends.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 wow move-up">
                    <div class="feature-app-landing__wrap">
                        <div class="feature-app-landing__inner">
                            <div class="feature-app-landing__icon">
                                <div class="icon">
                                    <i class="fa fa-rocket"></i>
                                </div>
                            </div>
                            <div class="feature-app-landing__content">
                                <h5 class="heading mb-10">Social Media </h5>
                                <div class="text">Our presence on a wide range of social platforms leads to better marketing results, larger customer base & bigger cooperating opportunities.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 wow move-up">
                    <div class="feature-app-landing__wrap">
                        <div class="feature-app-landing__inner">
                            <div class="feature-app-landing__icon">
                                <div class="icon">
                                    <i class="fa fa-arrow-alt-to-bottom"></i>
                                </div>
                            </div>
                            <div class="feature-app-landing__content">
                                <h5 class="heading mb-10">SEO Marketing </h5>

                                <div class="text">From meticulous research on search engine optimization, we have created creative marketing strategies to increase the SEO efficiency.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 wow move-up">
                    <div class="feature-app-landing__wrap">
                        <div class="feature-app-landing__inner">
                            <div class="feature-app-landing__icon">
                                <div class="icon">
                                    <i class="fa fa-bolt"></i>
                                </div>
                            </div>
                            <div class="feature-app-landing__content">
                                <h5 class="heading mb-10">Keyword Search</h5>

                                <div class="text">Studies showed that the choice of appropriate keywords for your sites' content, portfolios as well as posts help increase the site ranking.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 wow move-up">
                    <div class="feature-app-landing__wrap">
                        <div class="feature-app-landing__inner">
                            <div class="feature-app-landing__icon">
                                <div class="icon">
                                    <i class="fa fa-mobile-alt"></i>
                                </div>
                            </div>
                            <div class="feature-app-landing__content">
                                <h5 class="heading mb-10">Email Optimization </h5>

                                <div class="text">Keep your audiences in touch with your company’s latest news, upcoming events, and new product launch via newsletters delivered to inbox.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: '',
    };
</script>

<style lang="scss">

</style>